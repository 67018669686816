import '../scss/style.scss';
import '../scss/responsive.scss';
import { config, dom, library } from '@fortawesome/fontawesome-svg-core';
import {
  faTwitterSquare,
  faFacebookSquare,
  faInstagram
} from '@fortawesome/free-brands-svg-icons';
import { faComments } from '@fortawesome/free-regular-svg-icons';

library.add(faTwitterSquare, faFacebookSquare, faInstagram);

dom.i2svg();
